.faq-style-wrapper .faq-body .faq-row {
  border-bottom: none;
}

.faq-style-wrapper .faq-title {
  text-align: center;
}

.faq-row-wrapper {
  padding-left: 12px;
}

#arcus{
  border-top: 2px solid #FF8600;
  width:60px;
  margin-top:10px!important;
}

#arcus-left{
  border-top: 2px solid #FF8600;
  width:60px;
  margin-top:10px!important;
  margin-left: 0px;
}
